@import '../../global/variables.scss';

.t-404 {
  height: 92vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 50px;
    margin-bottom: 80px;
  }

  button {
    background: none;

    font-size: 20px;
    color: $medium-pink;

    cursor: pointer;
  }
}
