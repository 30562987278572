@import "../global/variables.scss";

#root {
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  background-color: $base-color;
  color: white;
  max-width: 100vw;
  height: auto;
  padding: 0 45px 0 45px;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 3px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #333;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #ccc;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Krona One', sans-serif;
}

p, strong, span, a {
  font-family: 'Montserrat', sans-serif;
}

.green {
  color: $light-green;
}

[data-anime="scroll"] {
  opacity: 0;
  transform: translate3d(-30px, 0, 0);
  transition: .7s;
}

[data-anime="scroll"].ativo {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

@media only screen and (max-width: 768px){
  body {
    padding: 0;
  }
}