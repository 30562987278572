@import "../../global/variables.scss";

.t-building {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: space-around;

  img {
    height: 50px;
    cursor: pointer;
    z-index: 2;

    &:hover {
      animation: shakeScale 1.5s ease;
    }
  }

  .pink {
    color: $light-pink;
  }

  .green {
    color: $light-green;
  }

  &__title {
    position: relative;
    display: flex;
    font-size: 70px;
    line-height: 90px;

    z-index: 1;
    h2 {
      margin-top: 62px;
      animation: shake 2.5s infinite ease;
    }

    span {
      font-family: 'Krona One', sans-serif;
      color: $light-green;
    }

    &-pink--boxGradient{
      position: absolute;
      z-index: -5;
      height: 180px;
      width: 700px;

      background: transparent linear-gradient(270deg, #FF05BE 0%, #FF028900 100%) 0% 0% no-repeat padding-box;

      top: 55%;  
      left: 50%; 
      transition: all 0.3s ease-in-out;
  
      transform: translate(-75%, -50%) matrix(0.87, -0.5, 0.5, 0.87, 0, 0); 
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    p {
      font-size: 30px;
      margin-bottom: 20px;
      font-family: 'Krona One', sans-serif;
      span {
        font-family: 'Krona One', sans-serif;;
      }
    }

    &-wrap {
      display: flex;
      &Item {
        padding: 0 10px;
        a {
          display: flex;
          align-items: center;
          color:white;
          text-decoration: none;
          font-family: 'Montserrat', sans-serif;
          margin-bottom: 15px;

  
          img {
            height: 30px;
            margin-right: 10px;
            cursor: pointer;
            transition: transform .150s ease-in-out;
          }

          span {
            position: relative;
            &::after {
              content: '';
              transition: width 0.4s ease;
              width: 0;
              position: absolute;
              left: 50%;
              top: 100%;
              height: 2px;
              background-color: white;
              transform: translateX(-50%);
              margin-top: 5px;
            }
          }

          &:hover {
            img {
              animation: none;
              transform: scale(1.1);
            }

            span::after {
              width: 50%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px){
  html {
    overflow-x: hidden;
  }

  body {
    overflow-x: hidden;
  }

  img {
    z-index: 5;
  }

  h2 {
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .t-building__title-pink--boxGradient {
    z-index: -2;
    top: 150%;
    height: 130px;
  }

  .t-building__contact-wrap {
    flex-direction: column;
  }
}

//Animations
@keyframes shakeScale {
  0%   { transform: scale(1,1)    translateY(0); }
  10%  { transform: scale(1.1,.9) translateY(0); }
  30%  { transform: scale(.9,1.1) translateY(-1rem); }
  50%  { transform: scale(1,1)    translateY(0); }
  57%  { transform: scale(1,1)    translateY(-0.5px); }
  64%  { transform: scale(1,1)    translateY(0); }
  100% { transform: scale(1,1)    translateY(0); }
}