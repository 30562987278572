.t-footer{
  display: flex;
  justify-content: center;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-top: 1px solid #fff;
    padding: 10px 0;
    width: 100%;

    p {
      max-width: 406px;

      font-size: 12px;
      line-height: 20px;
    }

    &-social {
      display: flex;
      align-items: center;

      a {
        img {
          margin-right: 23px;
          height: 35px;
        }
        &:last-child {
          img {
            margin: 0;
          }
        }
      }
    }
  }
}

.t-cookie-lgpd {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 80%;
  border-radius: 8px;
  background: #fff;
  z-index: 5050;
  transition: all ease .4s;
  padding: 24px;
  bottom: 24px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  &.is--cookie {
      opacity: 1;
      visibility: visible;
      pointer-events: initial;
  }
  &-infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--title {
      font-size: 14px;
      line-height: 24px;
      color: #000;
      font-family: 'Krona One', sans-serif;
    }
    &--text {
      flex: 1;
      font-size: 12px;
      line-height: 20px;
      color: #000;
      margin: 0 25px;
      strong {
        font-weight: 600;
      }
    }
  }
  &-accept {
    background: #EA3588;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    padding: 9px 20px;
    font-size: 14px;
    appearance: none;
    border: none;
    font-family: 'Krona One', sans-serif;
    margin-right: 15px;
  }
  &-close {
    background: transparent;
    color: #EA3588;
    text-decoration: underline;
    cursor: pointer;
    padding: 9px 0;
    font-size: 14px;
    appearance: none;
    border: none;
    font-family: 'Krona One', sans-serif;
  }
}

@media only screen and (max-width: 768px){
  .t-footer {
    &__content {
      flex-direction: column;
      padding: 10px 10px;
      p {
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 10px;
      }

      &-social {
        a {
          img {
            margin-right: 10px;
            height: 25px;
          }
        }

        &::after {
          content: '';
          width: 32vw;
          height: 2px;
          background-color: white;
          margin-left: 10px;

        }

        &::before {
          content: '';
          width: 32vw;
          height: 2px;
          background-color: white;
          margin-right: 10px;
        }
      }
    }    
  }

  .t-cookie-lgpd {
    bottom: 0;
    max-width: 85%;
    border-radius: 8px 8px 0 0;
    padding: 24px 24px 32px;
    &-infos {
      flex-direction: column;
      &--text {
        margin: 16px auto 32px;
        text-align: center;
      }
    }
  }
}
