@import '../../global/variables.scss';

.t-navBar {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: calc(100% - 90px);

  padding-top: 21px;

  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 

  // background-color: $base-color;
  background-color: transparent;
  z-index: 1000;

  img {
    cursor: pointer;
    width: 160px;
    z-index: 10;
    &:hover {
      animation: shakeScale 1s ease;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    //sobrepondo enquanto não existe o atalho
    justify-content: flex-end;
    width: 40%;
    max-width: 500px;
    min-width: 400px;
    // background-color: $base-color;
    background-color: transparent;
    z-index: 5;

    &-menu {
      display: flex;
      align-items: center;
      gap: 8px;
      position: relative;

      cursor: pointer;

      div {
        height: 18px;
        width: 0px;

        background-color: $light-green;
        border: 1px solid $light-green;
        transform: rotate(20deg);

        transition: all 0.4s ease-in-out;
      }

      p {
        font-size: 16px;
      }

      &::after {
        content: '';
        transition: all 0.4s ease;
        width: 0;
        position: absolute;
        left: 50%;
        top: 100%;
        height: 2px;
        background-color: $light-green;
        transform: translateX(-50%);
        margin-top: 5px;
      }
  
      &:hover {
        &::after {
          width: 50%;
        }

        div.t-navBar__menu-icon {
          &:nth-of-type(1){
            transform: rotate(90deg) translateY(-15px);
          }
          &:nth-of-type(2){
            transform: rotate(270deg) translateY(5px) translateX(6px);
          }
          &:nth-of-type(3){
            transform: rotate(270deg) translateY(-5px) translateX(-6px);
          }
        }
      }

      &.open {
        div.t-navBar__menu-icon {
          &:nth-of-type(1){
            transform:  translateX(-100px);
            opacity: 0;
          }
          &:nth-of-type(2){
            transform: rotate(45deg) translateY(-5px) translateX(5px);
          }
          &:nth-of-type(3){
            transform: rotate(-45deg) translateY(-2px) translateX(-2px);
          }
        }
      }
    }

    &-formShortcut {
      display: flex;
      display: none;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      max-width: 280px;
      min-width: 280px;

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 100%;
        &::after {
          content: '';
          transition: all 0.4s ease;
          width: 0;
          position: absolute;
          left: 50%;
          top: 100%;
          height: 2px;
          background-color: $light-green;
          transform: translateX(-50%);
          margin-top: 5px;
        }
    
        &:hover {
          &::after {
            width: 50%;
          }
        }
      }

      &-click {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 75px;
        height: 34px;

        border: 1px solid $light-green;
        border-radius: 8px;

        p {
          color: $light-green;
          padding: 8px;
        }
      }
    }
  }

  &__openMenu.open {
    transform: translateX(0px) !important;
  }

  &__openMenu{
    position: fixed;
    background-color: black;
    top: 58px;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    transition: all 0.3s ease;
    height: calc(100vh - 158px);
    // transform: translateX(500px);

    &-items {
      display: flex;
      flex-direction: column;
      transform: translateY(-100px);
    }

    button {
      position: relative;
      background: none;
      border: 0;
      color: #fff;
      margin-bottom: 30px;
      font-size: 56px;
      font-family: 'Krona One', sans-serif;

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 0%;
        height: 0.125rem;
        margin-top: 5px;
        background: linear-gradient(90deg,#FF0289 10%,#ff0189 30%,#a6e22e 75%);
        transition: all 0.2s ease;
      }

      &:hover {
        cursor: pointer;
        &::after {
          width: 100%;
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 99%;
      left: 0;
      width: 100%;
      height: 5px;
      background: linear-gradient(90deg,#FF0289 10%,#ff0189 30%,#a6e22e 75%);
    }

    &:before {
      content: "";
      width: 100%;
      height: 60px;
      background: black;
      position: absolute;
      bottom: 100%;
      left: 0;
      z-index: 1;
    }
  }
}

@media only screen and (max-width: 768px){
  .t-navBar {
    max-width: 100%;
    padding: 15px;
    box-sizing: border-box;
    -webkit-box-shadow: 3px 2px 29px 18px #000000; 
    box-shadow: 3px 2px 29px 18px #000000;
    background-color: $base-color;

    &__wrapper {
      width: auto;
      max-width: inherit;
      min-width: inherit;
      transform: translateY(7px);

      &-formShortcut {
        display: none;
      }
    }

    &__openMenu-items {
      transform: translateY(-50px);
    }

    button {
      font-size: 20px;
    }
  }
}

//Animations

@keyframes shakeScale {
  0%   { transform: scale(1,1)    translateY(0); }
  10%  { transform: scale(1.1,.9) translateY(0); }
  30%  { transform: scale(.9,1.1) translateY(-1rem); }
  50%  { transform: scale(1,1)    translateY(0); }
  57%  { transform: scale(1,1)    translateY(-0.5px); }
  64%  { transform: scale(1,1)    translateY(0); }
  100% { transform: scale(1,1)    translateY(0); }
}