@import '../../global/variables.scss';

.t-vtex {
  margin: 180px 0 250px;

  .green {
    color: $light-green;
  }
  .pink {
    color: $light-pink;
  }

  &__title {
    font-size: 5vw;
    line-height: 130%;
    font-family: 'Krona One', sans-serif;
    .green {
      font-family: 'Krona One', sans-serif;
    }
  }

  &__subtitle {
    // font-size: 36px;
    // line-height: 56px;
    font-size: 2.4vw;
    line-height: 4vw;
  }

  &__text {
    // font-size: 16px;
    // line-height: 26px;
    font-size: 1.15vw;
    line-height: 1.85vw;
  }

  &__top {
    display: flex;
    padding-left: calc(10% - 45px);

    h2 {
      font-size: 5vw;
      line-height: 130%;
      font-weight: 600;
      letter-spacing: 3px;
      padding-left: 40px;
    }

    &-left {
      width: 50%;
      padding: 0 11% 150px 0;
    }

    &-right {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        max-width: 65%;
        width: 100%;
        transform: translateY(-100px);
      }
      &::after {
        content: '';
        position: absolute;
        top: -180px;
        right: -46px;
        background: #EA3588;
        width: calc(100% + 45px);
        height: calc(100% + 180px);
        z-index: -1;
      }
    }

    &-subtitle {
      font-size: 2.8vw;
      line-height: 140%;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
    }

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      background-color: transparent;
      color: white;
      border: 1px solid white;
      border-radius: 50px;
      padding: 3px 3px;
      width: 287px;
      text-align: left;
      height: 55px;
      margin-top: 40px;
    
      span {
        width: 100%;
        padding: 11px 20px;
        border-radius: 50px;
        transition: background-color .3s ease;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -1px;
        height: 100%;
        width: 40px;
        background-color: #fff;
        border-radius: 0 50px 50px 0;
        transition: all .3s ease;
      }

      &:hover {
        cursor: pointer;
        color: #000;
        span {
          background-color: $light-green;
        }
        &::after {
          top: 40%;
          right: 20px;
          background-color: transparent;
          border: solid white;
          border-width: 0 2px 2px 0;
          border-radius: 0;
          display: inline-block;
          height: 10px;
          width: 10px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
      }
    }
  }

  &__whatIs {
    display: flex;
    padding: 80px calc(6% - 45px) 120px calc(3% - 45px);
    &-left {
      width: 40%;
    }
    &-right {
      width: 60%;
      padding-top: 30px;
      border-top: 1px solid #FF0289;
      margin-left: 4%;
      .t-vtex__text {
        padding: 30px 0 0 80px;
      }
    }
    .t-vtex__title {
      text-align: center;
    }
  }

  &__pink {
    padding: 88px calc(6% - 45px) 136px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -46px;
      background: #EA3588;
      width: calc(100% + 92px);
      height: 100%;
      z-index: -1;
    }

    .t-vtex__title {
      .green {
        margin-left: 40px;
      }
    }

    &-infos {
      display: flex;
      margin-top: 82px;
      justify-content: center;
      p {
        width: 40%;
        font-size: 4vw;
        line-height: 130%;
        font-weight: 600;
        text-align: center;
      }
    }
  }

  &__experience {
    display: flex;
    padding: 120px 0 120px calc(6% - 45px);

    &-left {
      width: 54%;
      padding-right: 8%;
      box-sizing: border-box;

      .t-vtex__title {
        position: relative;
        &::after {
          content: "{";
            color: #EA3588;
            font-size: 5vw;
            position: absolute;
            right: -10%;
            top: 10px;
        }

        .green {
          margin-left: 30px;
        }
      }

      .t-vtex__subtitle {
        margin: 40px 0 24px;
        padding-right: 12%;
      }
    }

    &-right {
      width: 46%;
      padding-left: 2%;
      img {
        width: 100%;
      }
    }

    .t-vtex__title {
      transform: translateX(-40px);
    }
  }

  &__differentials {
    padding: 0 calc(2% - 45px) 0 calc(6% - 45px);
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 120px;
      .t-vtex__title {
        .green {
          margin-left: 40px;
        }
      }
      .t-vtex__subtitle {
        padding: 20px 10% 0 0;
      }
    }

    .t-vtex__text {
      text-align: center;
      padding: 0 10%;
      margin: 80px 0 120px;
    } 

    &-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
    }

    &-item {
      flex: 0 0 25%;
      margin: 0 4% 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &--title {
        font-size: 2vw;
        line-height: 130%;
        text-align: center;
        padding: 0 6%;
        font-weight: 600;
        margin-bottom: 24px;
        min-height: 70px;
      }

      &--text {
        // font-size: 20px;
        // line-height: 32px;
        font-size: 1.2vw;
        line-height: 2.2vw;
        text-align: justify;
      }
    }

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      background-color: transparent;
      color: white;
      border: 1px solid white;
      border-radius: 50px;
      padding: 3px 3px;
      width: 287px;
      text-align: left;
      height: 55px;
      margin: 40px auto 0;
    
      span {
        width: 100%;
        padding: 11px 20px;
        border-radius: 50px;
        transition: background-color .3s ease;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -1px;
        height: 100%;
        width: 40px;
        background-color: #fff;
        border-radius: 0 50px 50px 0;
        transition: all .3s ease;
      }

      &:hover {
        cursor: pointer;
        color: #000;
        span {
          background-color: $light-green;
        }
        &::after {
          top: 40%;
          right: 20px;
          background-color: transparent;
          border: solid white;
          border-width: 0 2px 2px 0;
          border-radius: 0;
          display: inline-block;
          height: 10px;
          width: 10px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
      }
    }
  }
}

@media only screen and (max-width: 768px){
  .t-vtex {
    padding: 0 20px;
    margin: 120px 0 100px;

    &__title {
      font-size: 30px !important;
      line-height: 38px !important;
    }
  
    &__subtitle {
      font-size: 20px;
      line-height: 26px;
    }

    &__text {
      font-size: 16px;
      line-height: 26px;
    }

    &__top {
      flex-direction: column;
      padding: 0;
  
      h2 {
        font-size: 36px !important;
        line-height: 42px !important;
        letter-spacing: 3px;
        padding: 0;
        text-align: center;
        margin-bottom: 20px;
      }
  
      &-left {
        width: 100%;
        padding: 0;
      }
  
      &-right {
        width: 100%;
        padding: 60px 0;
        img {
          max-width: 70%;
          width: 100%;
          transform: none;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: -45px;
          background: #EA3588;
          width: calc(100% + 90px);
          height: calc(100%);
          z-index: -1;
        }
      }
  
      &-subtitle {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        text-align: center;
      }

      button {
        width: 80%;
        margin: 40px auto 20px;
      }
    }

    &__whatIs {
      flex-direction: column;
      padding: 50px 0 0;
      &-left {
        width: 100%;
        margin-bottom: 30px;
      }
      &-right {
        width: 90%;
        padding: 30px 5% 40px;
        border-top: 1px solid #FF0289;
        margin-left: 0;
        .t-vtex__text {
          padding: 30px 0 0 40px;
        }
      }
      .t-vtex__title {
        text-align: center;
      }
    }

    &__pink {
      padding: 40px 0 50px;
      position: relative;
  
      .t-vtex__title {
        .green {
          margin-left: 20px;
        }
      }
  
      &-infos {
        display: flex;
        margin-top: 30px;
        justify-content: center;
        p {
          width: 45%;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
        }
      }
    }

    &__experience {
      flex-direction: column;
      padding: 60px 5%;
  
      &-left {
        width: 100%;
        padding-right: 0;
  
        .t-vtex__title {
          margin-bottom: 40px;
          &::after {
            content: "{";
              font-size: 32px;
              right: -5%;
          }
  
          .green {
            margin-left: 30px;
          }
        }
  
        .t-vtex__subtitle {
          margin: 0 0 30px;
          padding-right: 0;
        }

        .t-vtex__text {
          text-align: center;
        }
      }
  
      &-right {
        width: 100%;
        padding-left: 0;
        margin-top: 30px;
        img {
          width: 100%;
        }
      }
  
      .t-vtex__title {
        transform: translateX(-10px);
      }
    }
  
    &__differentials {
      padding: 0;
      &-top {
        flex-direction: column;
        margin-bottom: 50px;
        .t-vtex__title {
          margin-bottom: 20px;
          text-align: center;
          .green {
            margin: 0;
          }
        }
        .t-vtex__subtitle {
          padding: 0 5%;
        }
      }
  
      .t-vtex__text {
        text-align: center;
        padding: 0 8%;
      } 
  
      &-items {
        display: flex;
        flex-direction: column;
        padding: 0 10%;
      }
  
      &-item {
        margin: 0 auto 70px;
  
        &--title {
          font-size: 22px;
          line-height: 20px;
          padding: 0 8%;
          margin-bottom: 20px;
          min-height: 0;
        }
  
        &--text {
          font-size: 16px;
          line-height: 26px;
        }

      }

      button {
        margin: 20px auto 0;
      }
    }
  }
}


//Animations

@keyframes blink {
  50% {
    color: black;
  }
}

@keyframes shake {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(1rem); }
  100% { transform: translateY(0); }
}

@keyframes shakeX {
  0%   { transform: translateX(0); }
  50%  { transform: translateX(1rem); }
  100% { transform: translateX(0); }
}
