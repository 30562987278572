@import '../../global/variables.scss';

.t-solutions {
  margin-top: 100px;
  margin-bottom: 250px;

  &__title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 300px;

    h2 {
      z-index: 5;
      font-size: 5vw;
      line-height: 130%;
      max-width: 50vw;

      span {
        font-family: 'Krona One', sans-serif;
      }
    }

    img {
      z-index: -1;
      width: 40vw;
    }
  }

  &__quantity {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 400px;
      margin-right: 40px;
    }

    &-desc {
      max-width: 400px;

      &Item {
        &--text {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          font-size: 40px;
          color: $light-green;

          span {
            margin-right: 2px;
            color: $light-pink;
          }

          .counter1 {
            &::after {
              content: 'projetos no ar';
              width: 60px;
              margin-left: 10px;
              font-size: 10px;
              color: #fff;
              display: inline-block;
            }
          }

          .counter2 {
            &::after {
              content: 'linhas de código';
              width: 60px;
              margin-left: 10px;
              font-size: 10px;
              color: #fff;
              display: inline-block;
            }
          }

        }
      }
      p {
        font-size: 22px;
        line-height: 35px;
      }
    }
  }

  &__whatWeDo {
    margin-bottom: 150px;
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 10px;
      h2 {
        font-size: 40px;
        line-height: 60px;
        span {
          font-family: 'Krona One', sans-serif;
        }
      }

      p {
        max-width: 368px;
        font-size: 24px;
        line-height: 32px;
      } 
    }

    &-table {
      display: flex;
      padding: 0 40px;
      justify-content: space-between;
      &Column {
        display: flex;
        flex-direction: column;
        width: 28%;
        
        button {
          cursor: pointer;
          background: none;
          border: none;
          position: relative;
          margin-bottom: 20px;
          text-decoration: none;
          color: #fff;
          font-size: 20px;
          font-weight: 500;
          text-align: left;
          padding: 15px 0;
          border-bottom: 1px solid $light-pink;
          width: 100%;

          &::before {
            content: '/';
            font-size: 20px;
            margin-right: 10px;
            color: $light-green;
          }

          &::after {
            opacity: 0;
            pointer-events: none;

            content: 'Preciso disso agora!';
            background: url('../../assets/images/arrowRight.svg') $light-pink 97% no-repeat;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            display: flex;
            align-items: center;
            padding-left: 10px;
            height: 100%;
            width: 101%;

            transform: translateX(-2px) translateY(1px);
            transition: all .3s ease;
          }


          &:hover {
            &::after {
              opacity: 1;
              pointer-events: all;
            }
          }
        }
      }
    }
  }

  &__skills {
    display: flex;
    flex-direction: column;

    &-title {
      display: flex;
      justify-content: space-between;
      h2 {
        font-size: 40px;
        line-height: 60px;

        span {
          font-family: 'Krona One', sans-serif;
        }
      }

      p {
        font-size: 24px;
        line-height: 32px
      }
    }

    &-wrap {
      display: flex;
      border-top: 1px solid $light-pink;
      border-bottom: 1px solid $light-pink;
      padding: 40px 0;

      &Items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    
        &--flex {
          display: flex;
          align-items: center;
          min-width: 200px;

          text-decoration: none;
          color: #fff;

          &:hover {
            &::after {
              content: '*/';
              font-size: 20px;
              color: $light-green;
              animation: blink 0.5s step-end infinite alternate;
              margin-left: 10px;
            }
          }

          img {
            height: 40px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  &__buildTogether {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
    h2 {
      font-size: 40px;
      line-height: 60px;
      transform: translateY(50px);

      span {
        font-family: 'Krona One', sans-serif;
      }
    }

    &-circle {
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 50%;
      background-color: #130F22;
      height: 350px;
      width: 350px;

      img {
        height: 180px;
        animation: shake 1.5s infinite ease-in-out;
      }

    }
  }

  &__home {
    display: flex;
    align-items: center;
    justify-content: center;
    
    span {
      font-size: 40px;
      color: $light-green;
      margin-right: 15px;
      transition: color .3s ease;
    }

    h2 {
      font-size: 30px;
      margin-right: 10px;
      transition: color .3s ease;
    }

    &:hover {
      cursor: pointer;

      span {
        color: $light-pink;
      }

      h2 {
        color: $light-green;
      }

      .t-aboutUs__solutions-arrowLine, .t-solutions__home-arrowLine {
        width: 200px;
      }
    }

    &-arrow {
      display: flex;
      align-items: center;

      &Line {
        width: 0px;
        height: 8px;
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(122,17,73,1) 100%);
        border-radius: 9px;
        transform: translateX(30px);
        z-index: -1;
        transition: all .3s ease;
      }

      img {
        height: 60px;
      }
    }
  }
}

@media only screen and (max-width: 768px){
  .t-solutions {
    padding: 0 20px;
    margin-bottom: 100px;
    &__title {
      padding-top: 50px;
      margin-bottom: 100px;
      justify-content: center;
      h2 {
        font-size: 28px !important;
        line-height: 36px !important;
        max-width: 100vw;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 50%;  
        left: 50%;

        transform: translate(-50%, -50%);
      }

      img {
        width: 100%;
      }
    }

    &__quantity {
      flex-direction: column;
      margin-bottom: 100px;
      img {
        max-width: 50%;
        margin-right: 10px;
      }

      &-desc {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &Item {
          &--text {
            span {
              font-size: 20px;
            }

            h3 {
              font-size: 18px !important;
              line-height: 18px !important;
              display: flex;
              align-items: center;

              &::after {
                font-size: 16px;
              }
            }
          }
        }
      }

      p {
        line-height: 20px;
      }
    }

    &__whatWeDo {
      margin-bottom: 80px;
      &-title {
        flex-direction: column;
        align-items: center;
        h2 {
          text-align: center;
          font-size: 28px !important;
          line-height: 28px !important;
          margin-bottom: 20px;
        }
        p {
          text-align: center;
          font-size: 16px;
          line-height: 16px;
        }
      }

      &-table {
        padding: 0;
        flex-direction: column;

        &Column {
          width: 100%;
          button {
            margin-bottom: 5px;
          }
        }
      }
    }

    &__skills {
      &-title {
        flex-direction: column;
        h2 {
          font-size: 28px !important;
          line-height: 28px !important;
          margin-bottom: 20px;
          text-align: center;
        }

        p {
          text-align: center;
          font-size: 16px;
          line-height: 18px;
          margin-bottom: 10px;
        }
      }

      &-wrap {
       &Items {
        gap: 15px;
        justify-content: space-between;

        &--flex {
          min-width: 131px;
          img {
            height: 35px;
          }
        }
       }
      }
    }

    &__buildTogether {
      h2 {
        font-size: 28px !important;
        line-height: 28px !important;
        margin-bottom: 20px;
        text-align: center;
      }

      &-circle {
        height: 300px;
        width: 300px;

        img {
          height: 140px;
        }
      }
    }

    &__home {
      display: flex;
      align-items: center;
      justify-content: center;

      span{
        font-size: 30px !important;
      }

      h2 {
        font-size: 18px !important;
        line-height: 18px !important;
      }
      &-arrow {
        animation: shakeX 1.5s infinite ease-in-out;
        
        img {
          height: 60px;  
        }
      }
    }
  }
}


//Animations

@keyframes blink {
  50% {
    color: black;
  }
}

@keyframes shake {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(1rem); }
  100% { transform: translateY(0); }
}

@keyframes shakeX {
  0%   { transform: translateX(0); }
  50%  { transform: translateX(1rem); }
  100% { transform: translateX(0); }
}
