@import '../../global/variables.scss';

.t-home, .t-aboutUs {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;

  &:hover{
    .t-home__haveFun-title-pink--boxGradient {
      transform: translate(-75%, -50%) matrix(0.87, -0.5, 0.5, 0.87, 0, 0) scale(1.1);
    }
  }

  &__haveFun {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    z-index: -1;

    &-title {
      position: relative;
      display: flex;
      font-size: 90px;
      line-height: 140px;
      text-align: center;
      z-index: 1;
      h2 {
        animation: shake 2.5s infinite ease;
      }

      span {
        font-family: 'Krona One', sans-serif;
        color: $light-green;
      }

      &-pink--boxGradient{
        position: absolute;
        z-index: -5;
        height: 300px;
        width: 500px;

        background: transparent linear-gradient(270deg, #FF05BE 0%, #FF028900 100%) 0% 0% no-repeat padding-box;

        top: 70%;  
        left: 50%; 
        transition: all 0.3s ease-in-out;
    
        transform: translate(-75%, -50%) matrix(0.87, -0.5, 0.5, 0.87, 0, 0);
        
        
      }

    }

    img {
      animation: shake 2.5s infinite ease;
      transform: rotate(90deg);
      position: absolute;
      bottom: 90px;
      height: 30px;
      filter: brightness(0.5);

    }
  }

  &__weAre {
    display: flex;
    margin-top: 150px;
    margin-bottom: 300px;
    justify-content: space-between;

    &-wrap {
      &One {
        max-width: 460px;
        h2 {
          margin-left: 162px;
          font-size: 38px;
          line-height: 60px;
          text-align: left;

          span {
            font-family: 'Krona One', sans-serif;
            color: $light-green;
          }
        }   
      }

      &Three {
        display: flex;
        position: relative;
        margin-right: 20vw;
        transform: translateY(10vh);

        .text-absolute {
          max-width: 295px;
          max-height: 213px;
          overflow: hidden;
          align-self: flex-end;
          font-size: 20px;
          line-height: 30px;
          position: absolute;
          z-index: 1;
          left: -180px;
          bottom: -100px;

          span {
            color: $medium-pink;
          }
        }

        img {
          max-height: 382px;
          position: relative;
        }

        &-cards {
          height: 145%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: absolute;
          right: -230px;
          top: -120px;

          &Item {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
            max-height: 240px;
            width: 290px;
            max-width: 290px;
            background-color: #FF0289;
            clip-path: polygon(25% 0%, 100% 0, 75% 100%, 0 100%);
            p {
              font-size: 26px;
              line-height: 26px;
              text-align: left;
              padding: 0 50px 0 50px;
              max-width: 250px;
            }
  
            span {
              font-size: 16px;
              line-height: 18px;
              margin-bottom: 21px;
              text-align: left;
              padding: 0 50px 0 50px;
            }
  
            &:last-child {
              background-color: #A6E22E;
              p {
                color: black;
              }
              span {
                color: black;
              }
            }
          }
        }
      }
    }
  }
  &__projects {
    margin-bottom: 80px;
  }

  &__projects, &__team {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-wrap {
      display: flex;

      h2 {
        font-size: 40px;
        line-height: 48px;
        text-align: left;
        margin-bottom: 80px;

        .green {
          color: $light-green;
        }
      }
      &Item--one {
        margin-right: 98px;

        .t-home__projects-wrapItem__card {
          margin-left: 98px;
        }
      }

      &Item--two {
        padding-top: 50px;
      }

      &Item__card {
        display: flex;
        flex-direction: column;
        margin-bottom: 102px ;
        height: 420px;
        width: 420px;

        img {
          height: 420px;
          width: 420px;
          transition: all 02s ease;
        }
        

        &:hover {
          cursor: pointer;

          .link-hover {
            color: $light-green;
            border-color: $light-green;
            transform: translateY(1px);
            opacity: 1;

            &::after {
              border-color: $light-green;
            }
          }
        }


        p {
          font-size: 22px;
          margin-bottom: 10px;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: white;
          font-family: 'Montserrat', sans-serif;
          text-decoration: none;
          cursor: pointer;

          opacity: 0;
          padding: 21px 0;
          transform: translateY(-60px) translateX(0);
          border-bottom: 1px solid white;
          transition: all .3s ease;

          &::after {
            content: '';
            border: solid white;
            border-width: 0 2px 2px 0;
            display: inline-block;
            height: 10px;
            width: 10px;
            transform: rotate(-45deg) translateX(-5px);
            -webkit-transform: rotate(-45deg) translateX(-5px);
            transition: all .3s ease;

          }
        }
      }
    }
    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      background-color: transparent;
      color: white;
      border: 1px solid white;
      border-radius: 50px;
      padding: 3px 3px;
      width: 287px;
      text-align: left;
    
      span {
        width: 100%;
        padding: 11px 20px;
        border-radius: 50px;
        transition: background-color .3s ease;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -1px;
        height: 100%;
        width: 40px;
        background-color: #fff;
        border-radius: 0 50px 50px 0;
        transition: all .3s ease;
      }

      &:hover {
        cursor: pointer;
        color: #000;
        span {
          background-color: $light-green;
        }
        &::after {
          top: 40%;
          right: 20px;
          background-color: transparent;
          border: solid white;
          border-width: 0 2px 2px 0;
          border-radius: 0;
          display: inline-block;
          height: 10px;
          width: 10px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
      }
    }
  }

  &__weCode {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;

    &-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 40px;

      h2 {
        font-size: 40px;
        line-height: 48px;
        text-align: left;
        margin-bottom: 10px;

        .green {
          color: $light-green;
        }
      }

      p {
        font-size: 14px;
        line-height: 22px;
        width: 30%;
      }
    }

    &-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      border-top: 1px solid $medium-pink;
      width: 90%;

      h3 {
        font-size: 25px;
        line-height: 33px;
        text-align: left;

        &::before {
          content: '/';
          font-size: 25px;
          margin-right: 15px;
          color: $light-green;

        }
      }

      p {
        width: 50%;
      }

      &:last-of-type {
        border-bottom: 1px solid $medium-pink;
        margin-bottom: 80px;
      }
    }
    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      background-color: transparent;
      color: white;
      border: 1px solid white;
      border-radius: 50px;
      padding: 3px 3px;
      width: 287px;
      text-align: left;
    
      span {
        width: 100%;
        padding: 11px 20px;
        border-radius: 50px;
        transition: background-color .3s ease;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -1px;
        height: 100%;
        width: 40px;
        background-color: #fff;
        border-radius: 0 50px 50px 0;
        transition: all .3s ease;
      }

      &:hover {
        cursor: pointer;
        color: #000;
        span {
          background-color: $light-green;
        }
        &::after {
          top: 40%;
          right: 20px;
          background-color: transparent;
          border: solid white;
          border-width: 0 2px 2px 0;
          border-radius: 0;
          display: inline-block;
          height: 10px;
          width: 10px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
      }
    }
  }
}

@media only screen and (max-width: 768px){
  .t-home {
    &__haveFun {
      align-items: center;
      height: 60vh;

      p {
        align-self: flex-start;
      }

      &-title{
        h2 {
          margin-left: 0;
          font-size: 40px !important;
          line-height: 50px !important;
        }

        &-pink--boxGradient {
          width: 400px;
          height: 150px;
        }
      }
    }

    &__weAre {
      flex-direction: column;
      align-items: center;
      margin-bottom: 80px;

      &-wrapOne {
        margin-bottom: 10px;
        max-width: 250px;

        h2 {
          margin: 0;
          text-align: center;
          font-size: 36px !important;
        }
      }

      &-wrapThree {
        margin: 0;
        margin-bottom: 80px;
        display: flex;
        flex-direction: column;

        .text-absolute {
          position: initial;
          order: 2;
          text-align: center;
          font-size: 18px;
          line-height: 26px;
        }

        img {
          max-height: auto;
          width: 100%;
          order: 1;
          margin-bottom: 20px;
        }

        &-cards {
          display: none;
        }
      }
    }

    &__projects {
      &-wrap {
        img {
          pointer-events: none;
          height: 120px;
          width: 120px;
        }
        &Item {
          &__card {
            margin-left: 0 !important;
            width: 120px;
            margin-bottom: 20px;

            a {
              font-size: 12px;
              padding: 16px 0;
            }
            
            p {
              font-size: 16px;
            }

            &.is--active{
              a {
                transform: translateY(0);
              }
            }
          }

          &--one {
            margin-right: 20px;

            h2 {
              font-size: 25px !important;
              line-height: 35px !important;
            }
          }
        }
      }

      button {
        width: 230px;

        span {
          padding: 6px 15px;
        }
      }
    }

    &__weCode {
      &-wrap {
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;

        h2 {
          text-align: center;
        }

        p {
          width: 100%;
          text-align: center;
        }
      }

      &-list {
        padding: 15px 0;
        flex-direction: column;
        align-items: flex-start;

        h3 {
          font-size: 16px;
          align-self: flex-start;
          margin-bottom: 15px;
        }

        p {
          width: 100%;
          font-size: 14px;
          line-height: 22px;
        }

        &:last-of-type {
          margin-bottom: 40px;
        }
      }

      button {
        width: 230px;

        span {
          padding: 6px 15px;
        }
      }
    }
  }
}

//Animations

@keyframes shake {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(1rem); }
  100% { transform: translateY(0); }
}
