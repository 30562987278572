@import '../../global/variables.scss';

.t-aboutUs {
  margin-top: 65px;
  height: 100%;

  .green {
    color: $light-green;
  }
  .pink {
    color: $light-pink;
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 300px;
    height: 1350px;

    &-stick {
      height: 100%;
      display: initial;
      width: 50%;
      
      h2 {
        top: 0;
        position: sticky;
        font-size: 3.2vw;
        line-height: 130%;
        max-width: 50vw;
        padding-top: 80px;
  
        span {
          font-family: 'Krona One', sans-serif;
        }
      }
    }

    .t-aboutUs__desc {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 80px;

      img {
        width: 28vw;
        align-self: center;
        margin-bottom: 10px;
      }

      p {
        max-width: 657px;
        margin-bottom: 62px;
  
        font-size: 36px;
        line-height: 56px;
  
        margin-right: 0;
  
        &::before {
          content: '';
          width: 100%;
          height: 2px;
          margin-bottom: 27px;
          background: linear-gradient(90deg, rgba(55,13,35,1) 0%, rgba(122,17,73,1) 100%);
        }
      }
  
      img {
        max-width: 657px;
      }
    }
  }

  &__preTeam {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 80px;

    h2 {
      font-size: 4vw;
      line-height: 130%;
      span {
        font-family: 'Krona One', sans-serif;
      }
    }

    &-wrap {
      display: flex;
      flex-direction: column;
      width: 40%;

      p {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 18px;

        &:last-child {
          font-size: 16px;
          line-height: normal;
          margin-bottom: 0;
        }
      }
    }
  }

  .t-aboutUs__team {
    display: flex;
    justify-content: center;

    .is--active {
      color: $light-green;
      border-color: $light-green;
      transform: translateY(1px);
      opacity: 1;

      &::after {
        border-color: $light-green;
      }
    }
  }

  .t-aboutUs__solutions {
    display: flex;
    align-items: center;
    justify-content: center;
    
    span {
      font-size: 40px;
      color: $light-green;
      margin-right: 15px;
      margin-bottom: 100px;
      transition: color .3s ease;
    }

    h2 {
      font-size: 30px;
      margin-bottom: 100px;
      margin-right: 10px;
      transition: color .3s ease;
    }

    &:hover {
      cursor: pointer;

      span {
        color: $light-pink;
      }

      h2 {
        color: $light-green;
      }

      .t-aboutUs__solutions-arrowLine, .t-solutions__home-arrowLine {
        width: 200px;
      }
    }

    &-arrow {
      display: flex;
      align-items: center;
      margin-bottom: 100px;

      &Line {
        width: 0px;
        height: 8px;
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(122,17,73,1) 100%);
        border-radius: 9px;
        transform: translateX(30px);
        opacity: 0;
        transition: all .3s ease;
      }

      img {
        height: 60px;
      }
    }
  }
}

@media only screen and (max-width: 768px){
  .t-aboutUs {
    padding: 0 20px;
    &__title {
      flex-direction: column;
      padding-top: 30px;
      margin-bottom: 30px;
      height: auto;

      &-stick {
        width: 100%;
        h2 {
          font-size: 26px !important;
          line-height: 36px !important;
          max-width: 100vw;
          z-index: 100;
          padding: 0;
          height: 300px;
        }
      }

      .t-aboutUs__desc {
        margin-right: 0;
        p{
          text-align: center;
          font-size: 20px;
          line-height: 28px;
        }
  
        img {
          width: 50%;
          &:last-of-type {
            width: 100%;
          }
        }
      }
    }

    &__preTeam {
      flex-direction: column;
      h2 {
        font-size: 28px !important;
        line-height: 28px !important;
        margin-bottom: 20px;
      }

      &-wrap {
        width: 100%;
        p {
          text-align: center;
        }
      }
    }

    &__team{
      &-wrap {

        img {
          pointer-events: none;
          width: 120px;
          height: 120px;
        }
        &Item {
          &__card {
            margin-left: 0 !important;
            width: 120px;
            margin-bottom: 20px;

            a {
              font-size: 12px;
              padding: 16px 0;
            }
            
            p {
              font-size: 16px;
            }
    
            &.is--active{
              a {
                transform: translateY(0);
              }
            }
          }
    
          &--one {
            margin-right: 20px;
    
            h2 {
              font-size: 25px !important;
              line-height: 35px !important;
            }
          }
        }
      }
    
      button {
        width: 230px;
    
        span {
          padding: 6px 15px;
        }
      }
    }

    &__solutions {
      span{
        font-size: 30px !important;
      }

      h2 {
        font-size: 18px !important;
        line-height: 18px !important;
      }
      &-arrow {
        animation: shakeX 1.5s infinite ease-in-out;
      }
    }
  }
}
